// import React, { useState, useEffect } from 'react';
// import { updateProductByID, getAllByCategory, getAllSubCategory } from '../../Constant/Api/Api';
// import { Modal, TextInput, Button, Select, TagsInput, NumberInput } from '@mantine/core';
// import uploadFile from '../../firebase/firebaseUploadFunction';
// import { MdDelete } from 'react-icons/md';

// // Utility function to create slug
// const generateSlug = (name) => {
//     return name
//         .toLowerCase()
//         .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
//         .replace(/(^-|-$)+/g, ''); // Remove leading and trailing hyphens
// };

// function EditProduct({ product, onClose }) {
//     const [productName, setProductName] = useState(product.name || '');
//     const [productDescription, setProductDescription] = useState(product.description || '');
//     const [productImages, setProductImages] = useState(product.images || []);
//     const [categories, setCategories] = useState([]);
//     const [subCategories, setSubCategories] = useState([]);
//     const [selectedCategory, setSelectedCategory] = useState(product.category?._id || '');
//     const [selectedSubCategory, setSelectedSubCategory] = useState(product.subCategory?._id || '');
//     const [tags, setTags] = useState(product.tags || []);
//     const [sellingPrice, setSellingPrice] = useState(product.sellingPrice || '');
//     const [mrp, setMrp] = useState(product.mrp || '');
//     const [specifications, setSpecifications] = useState(product.specifications || [{ key: '', value: [] }]);
//     const [slug, setSlug] = useState(product.slug || '');

//     useEffect(() => {
//         fetchCategories();
//         fetchSubCategories();
//     }, []);

//     useEffect(() => {
//         if (product) {
//             setProductName(product.name || '');
//             setProductDescription(product.description || '');
//             setProductImages(product.images || []);
//             setTags(product.tags || []);
//             setSellingPrice(product.sellingPrice || '');
//             setMrp(product.mrp || '');
//             setSpecifications(product.specifications || [{ key: '', value: [] }]);
//             setSlug(product.slug || '');
//         }
//     }, [product]);

//     const fetchCategories = async () => {
//         try {
//             const response = await fetch(getAllByCategory);
//             if (response.ok) {
//                 const data = await response.json();
//                 setCategories(data);
//                 setSelectedCategory(product.category?._id || '');
//             }
//         } catch (error) {
//             console.error('Error fetching categories:', error);
//         }
//     };

//     const fetchSubCategories = async () => {
//         try {
//             const response = await fetch(getAllSubCategory);
//             if (response.ok) {
//                 const data = await response.json();
//                 setSubCategories(data);
//                 setSelectedSubCategory(product.subCategory?._id || '');
//             }
//         } catch (error) {
//             console.error('Error fetching subcategories:', error);
//         }
//     };

//     const handleProductChange = (event) => {
//         setProductName(event.target.value);
//     };

//     const handleProductDescriptionChange = (event) => {
//         setProductDescription(event.target.value);
//     };

//     const handleSlugChange = (event) => {
//         setSlug(event.target.value);
//     };

//     const handleProductImagesChange = (e) => {
//         uploadFile(e)
//             .then((fireBaseUrl) => {
//                 setProductImages([...productImages, fireBaseUrl]);
//             })
//             .catch((error) => {
//                 console.error('Error uploading file:', error);
//             });
//     };

//     const handleCategoryChange = (value) => {
//         setSelectedCategory(value);
//     };

//     const handleSubCategoryChange = (value) => {
//         setSelectedSubCategory(value);
//     };

//     const handleSpecificationChange = (index, event) => {
//         const newSpecifications = specifications.map((spec, specIndex) => {
//             if (index === specIndex) {
//                 return { ...spec, [event.target.name]: event.target.value };
//             }
//             return spec;
//         });
//         setSpecifications(newSpecifications);
//     };

//     const handleSpecificationTagsChange = (index, value) => {
//         const newSpecifications = specifications.map((spec, specIndex) => {
//             if (index === specIndex) {
//                 return { ...spec, value };
//             }
//             return spec;
//         });
//         setSpecifications(newSpecifications);
//     };

//     const addSpecification = () => {
//         setSpecifications([...specifications, { key: '', value: [] }]);
//     };

//     const removeSpecification = (index) => {
//         setSpecifications(specifications.filter((_, specIndex) => specIndex !== index));
//     };

//     const handleDeleteImage = (index) => {
//         setProductImages(productImages.filter((_, i) => i !== index));
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault();

//         try {
//             const formData = {
//                 name: productName,
//                 description: productDescription,
//                 images: productImages,
//                 category: selectedCategory,
//                 subCategory: selectedSubCategory,
//                 tags,
//                 sellingPrice,
//                 mrp,
//                 specifications,
//                 slug: slug || generateSlug(productName),  // Fallback to generated slug if slug is empty
//             };

//             const response = await fetch(`${updateProductByID}${product._id}`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(formData),
//             });

//             const data = await response.json();

//             if (data) {
//                 onClose();
//                 setProductName('');
//                 setProductDescription('');
//                 setProductImages([]);
//                 setSelectedCategory('');
//                 setSelectedSubCategory('');
//                 setTags([]);
//                 setSellingPrice('');
//                 setMrp('');
//                 setSpecifications([{ key: '', value: [] }]);
//             }
//         } catch (error) {
//             console.error('Error updating product:', error);
//         }
//     };

//     return (
//         <Modal opened={true} onClose={onClose} title="Edit Product" size="40vw">
//             <div className="flex flex-row">
//                 {productImages?.map((image, index) => (
//                     <div key={index} className=''>
//                         <img src={image} alt={`Product Image ${index}`} className='object-scale-down w-32 h-32' />
//                         <MdDelete onClick={() => handleDeleteImage(index)} style={{ cursor: 'pointer', marginLeft: '10px' }} />
//                     </div>
//                 ))}
//             </div>
//             <form onSubmit={handleSubmit}>

//                 <TextInput
//                     label="Product Name"
//                     placeholder="Enter product name"
//                     value={productName}
//                     onChange={handleProductChange}
//                     required
//                 />
//                 <TextInput
//                     label="Description"
//                     placeholder="Enter product description"
//                     value={productDescription}
//                     onChange={handleProductDescriptionChange}
//                 />
//                 <TextInput
//                     label="Slug"
//                     placeholder="Slug"
//                     value={slug}
//                     onChange={handleSlugChange}
//                 />
//                 <Select
//                     label="Category"
//                     data={categories.map((category) => ({ label: category.name, value: category._id }))}
//                     value={selectedCategory}
//                     onChange={handleCategoryChange}
//                 />
//                 <Select
//                     label="Subcategory"
//                     data={subCategories.map((subcategory) => ({ label: subcategory.name, value: subcategory._id }))}
//                     value={selectedSubCategory}
//                     onChange={handleSubCategoryChange}
//                 />
//                 <TagsInput
//                     label="Press Enter to submit a tag"
//                     placeholder="Enter tag"
//                     value={tags}
//                     onChange={setTags}
//                 />
//                 <NumberInput
//                     label="Selling Price"
//                     placeholder="Enter selling price"
//                     value={sellingPrice}
//                     onChange={setSellingPrice}
//                 />
//                 <NumberInput
//                     label="MRP"
//                     placeholder="Enter MRP"
//                     value={mrp}
//                     onChange={setMrp}
//                 />
//                 <div>
//                     <label>Specifications</label>
//                     {specifications?.map((spec, index) => (
//                         <div key={index} style={{ display: 'flex', gap: '10px', alignItems: 'center', marginBottom: '10px' }}>
//                             <TextInput
//                                 placeholder="Key"
//                                 name="key"
//                                 value={spec.key}
//                                 onChange={(event) => handleSpecificationChange(index, event)}
//                                 style={{ width: '10vw' }}
//                             />
//                             <TagsInput
//                                 placeholder="Enter value"
//                                 value={spec.value}
//                                 onChange={(value) => handleSpecificationTagsChange(index, value)}
//                                 style={{ width: '100%' }}
//                             />
//                             <MdDelete className='text-red-900' onClick={() => removeSpecification(index)} style={{ cursor: 'pointer' }} />
//                         </div>
//                     ))}
//                     <Button type="button" onClick={addSpecification}>
//                         Add Specification
//                     </Button>
//                 </div>
//                 <input
//                     label="Product Images"
//                     placeholder="Select image"
//                     type="file"
//                     accept='image/*'
//                     onChange={(e) => handleProductImagesChange(e)}
//                     multiple
//                 />
//                 <Button type="submit">Update</Button>
//             </form>
//         </Modal>
//     );
// }

// export default EditProduct;


import React, { useState, useEffect } from 'react';
import { updateProductByID, getAllByCategory, getAllSubCategory } from '../../Constant/Api/Api';
import { Modal, TextInput, Button, Select, TagsInput, NumberInput } from '@mantine/core';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import uploadFile from '../../firebase/firebaseUploadFunction';
import { MdDelete } from 'react-icons/md';

const generateSlug = (name) => {
    return name
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)+/g, '');
};

export default function EditProduct({ product, onClose }) {
    const [productName, setProductName] = useState(product.name || '');
    const [productDescription, setProductDescription] = useState(product.description || '');
    const [productImages, setProductImages] = useState(product.images || []);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(product.category?._id || '');
    const [selectedSubCategory, setSelectedSubCategory] = useState(product.subCategory?._id || '');
    const [tags, setTags] = useState(product.tags || []);
    const [sellingPrice, setSellingPrice] = useState(product.sellingPrice || '');
    const [mrp, setMrp] = useState(product.mrp || '');
    const [specifications, setSpecifications] = useState(product.specifications || [{ key: '', value: [] }]);
    const [slug, setSlug] = useState(product.slug || '');

    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Superscript,
            SubScript,
            Highlight,
            Color,
            TextStyle,
            TextAlign.configure({ types: ['heading', 'paragraph'] })
        ],
        content: product.description || '',
        onUpdate: ({ editor }) => {
            setProductDescription(editor.getHTML());
        }
    });


    useEffect(() => {
        fetchCategories();
        fetchSubCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await fetch(getAllByCategory);
            if (response.ok) {
                const data = await response.json();
                setCategories(data);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchSubCategories = async () => {
        try {
            const response = await fetch(getAllSubCategory);
            if (response.ok) {
                const data = await response.json();
                setSubCategories(data);
            }
        } catch (error) {
            console.error('Error fetching subcategories:', error);
        }
    };

    const handleProductImagesChange = (e) => {
        uploadFile(e)
            .then((fireBaseUrl) => {
                setProductImages([...productImages, fireBaseUrl]);
            })
            .catch((error) => {
                console.error('Error uploading file:', error);
            });
    };

    const handleSpecificationChange = (index, event) => {
        const newSpecifications = specifications.map((spec, specIndex) => {
            if (index === specIndex) {
                return { ...spec, [event.target.name]: event.target.value };
            }
            return spec;
        });
        setSpecifications(newSpecifications);
    };

    const handleSpecificationTagsChange = (index, value) => {
        const newSpecifications = specifications.map((spec, specIndex) => {
            if (index === specIndex) {
                return { ...spec, value };
            }
            return spec;
        });
        setSpecifications(newSpecifications);
    };

    const addSpecification = () => {
        setSpecifications([...specifications, { key: '', value: [] }]);
    };

    const removeSpecification = (index) => {
        setSpecifications(specifications.filter((_, specIndex) => specIndex !== index));
    };

    const handleDeleteImage = (index) => {
        setProductImages(productImages.filter((_, i) => i !== index));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const formData = {
                name: productName,
                description: productDescription,
                images: productImages,
                category: selectedCategory,
                subCategory: selectedSubCategory,
                tags,
                sellingPrice,
                mrp,
                specifications,
                slug: slug || generateSlug(productName),
            };

            const response = await fetch(`${updateProductByID}${product._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (data) {
                onClose();
            }
        } catch (error) {
            console.error('Error updating product:', error);
        }
    };

    return (
        <Modal opened={true} onClose={onClose} title="Edit Product" size="xl">
            <div className="flex flex-row">
                {productImages?.map((image, index) => (
                    <div key={index} className=''>
                        <img src={image} alt={`Product Image ${index}`} className='object-scale-down w-32 h-32' />
                        <MdDelete onClick={() => handleDeleteImage(index)} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                    </div>
                ))}
            </div>
            <form onSubmit={handleSubmit}>
                <TextInput
                    label="Product Name"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                    required
                />

                <div className="mb-4">
                    <h3>Description</h3>
                    <RichTextEditor editor={editor} styles={{ root: { minHeight: 300 } }}>
                        <RichTextEditor.Toolbar sticky stickyOffset={0}>
                            <RichTextEditor.ControlsGroup>
                                <RichTextEditor.Bold />
                                <RichTextEditor.Italic />
                                <RichTextEditor.Underline />
                                <RichTextEditor.Strikethrough />
                                <RichTextEditor.ClearFormatting />
                                <RichTextEditor.Highlight />
                                <RichTextEditor.Code />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                                <RichTextEditor.H1 />
                                <RichTextEditor.H2 />
                                <RichTextEditor.H3 />
                                <RichTextEditor.H4 />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                                <RichTextEditor.Blockquote />
                                <RichTextEditor.Hr />
                                <RichTextEditor.BulletList />
                                <RichTextEditor.OrderedList />
                                <RichTextEditor.Subscript />
                                <RichTextEditor.Superscript />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                                <RichTextEditor.Link />
                                <RichTextEditor.Unlink />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ControlsGroup>
                                <RichTextEditor.AlignLeft />
                                <RichTextEditor.AlignCenter />
                                <RichTextEditor.AlignJustify />
                                <RichTextEditor.AlignRight />
                            </RichTextEditor.ControlsGroup>

                            <RichTextEditor.ColorPicker
                                colors={[
                                    '#25262b', '#868e96', '#fa5252', '#e64980',
                                    '#be4bdb', '#7950f2', '#4c6ef5', '#228be6',
                                    '#15aabf', '#12b886', '#40c057', '#82c91e',
                                    '#fab005', '#fd7e14',
                                ]}
                            />
                        </RichTextEditor.Toolbar>

                        <RichTextEditor.Content />
                    </RichTextEditor>
                </div>

                <TextInput
                    label="Slug"
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                />

                <Select
                    label="Category"
                    data={categories.map((category) => ({ label: category.name, value: category._id }))}
                    value={selectedCategory}
                    onChange={(value) => setSelectedCategory(value)}
                />

                <Select
                    label="Subcategory"
                    data={subCategories.map((subcategory) => ({ label: subcategory.name, value: subcategory._id }))}
                    value={selectedSubCategory}
                    onChange={(value) => setSelectedSubCategory(value)}
                />

                <TagsInput
                    label="Press Enter to submit a tag"
                    placeholder="Enter tag"
                    value={tags}
                    onChange={setTags}
                />

                <NumberInput
                    label="Selling Price"
                    value={sellingPrice}
                    onChange={setSellingPrice}
                />

                <NumberInput
                    label="MRP"
                    value={mrp}
                    onChange={setMrp}
                />

                <div>
                    <label>Specifications</label>
                    {specifications?.map((spec, index) => (
                        <div key={index} style={{ display: 'flex', gap: '10px', alignItems: 'center', marginBottom: '10px' }}>
                            <TextInput
                                placeholder="Key"
                                name="key"
                                value={spec.key}
                                onChange={(event) => handleSpecificationChange(index, event)}
                                style={{ width: '10vw' }}
                            />
                            <TagsInput
                                placeholder="Enter value"
                                value={spec.value}
                                onChange={(value) => handleSpecificationTagsChange(index, value)}
                                style={{ width: '100%' }}
                            />
                            <MdDelete className='text-red-900' onClick={() => removeSpecification(index)} style={{ cursor: 'pointer' }} />
                        </div>
                    ))}
                    <Button type="button" onClick={addSpecification}>
                        Add Specification
                    </Button>
                </div>

                <input
                    type="file"
                    accept='image/*'
                    onChange={(e) => handleProductImagesChange(e)}
                    multiple
                />

                <Button type="submit">Update</Button>
            </form>
        </Modal>
    );
}